import { useState, useEffect } from "react";

export const CompanySelfServiceFilter = ({ data, setCheckedSelfService }) => {
    const [checked, setChecked] = useState([])
    const selfServiceItems = data.companies.data.map(item => item.attributes.selfService)
    const selfServicesSet = new Set(selfServiceItems)
    const selfServicesArray = Array.from(selfServicesSet)

    const handleCheckSelfService = (event) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    useEffect(() => {
        setCheckedSelfService(checked)
    }, [checked])

    return (
        <>
            <p className="description-label">SelfService:</p>
            {selfServicesArray.map((item, index) => (
                <div key={index} className='filter-checkbox-row'>
                    <label>
                        <input
                            type="checkbox"
                            name="selfService"
                            value={item}
                            onChange={handleCheckSelfService}
                        /> {item === "No" ? "Nee" : "Ja"}
                    </label>
                </div>
            ))}
        </>
    )
}