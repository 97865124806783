import { Link } from "gatsby";
import Container from '@mui/material/Container';
import { HeroSection } from "../components/hero";
import { CompanyInfo } from "../components/company/company-info";
import Layout from "../components/layout";

export function Company({ pageContext }) {
  const { name, contact, opening, publishedAt, updatedAt, classes, shortDescription, longDescription, logo } = pageContext;
  classes.sort((c1, c2) => (c1.fromPrice > c2.fromPrice) ? 1 : -1)

  return (
    <Layout>
      <HeroSection title={name}></HeroSection>
      <Container>
        <div className='breadcrumb'>
          <Link to='/'>Home</Link> {`>`} <Link to='/autoverhuurders'>autoverhuurders</Link> {`>`} {name}
        </div>
      </Container>
      <CompanyInfo
        contact={contact}
        opening={opening}
        shortDescription={shortDescription}
        longDescription={longDescription}
        logo={logo}
      />
      <Container>
        <hr></hr>
        <div className='cards'>
          {classes.map((product, index) => (
            <Link to={`/huren/${product.category.name}/${product.displayName}-${name}`.toLowerCase().replace(/ +/g, '-')} key={index}>
              <div className="card">
                <div className="card__image">
                  <img src={product.images[0].url} alt={product.images[0].alternativeText}></img>
                </div>
                <div className="card__content">
                  <span className="title">{product.displayName}</span> <br></br>
                  V.a. € {product.fromPrice.toFixed(2).toString().replace(".", ",")  }
                  <div className="description-grid">
                    <div>
                      <span className="description-label">
                        Inbegrepen KM
                      </span>
                      <p>{product.includedMileageDay} km</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </Layout>
  );
}

export default Company;
