import { gql } from "@apollo/client"

export const GET_CLASSES = gql`
query getClasses($classesInput:ClassFiltersInput, $pagination:PaginationArg, $sort:[String]) {
  classes(filters: $classesInput, pagination: $pagination, sort: $sort) {
    data {
      attributes {
        category {
          data {
            attributes {
              name
            }
          }
        }
        type
        displayName
        rates {
          includedMileage
          price
        }
        fromPrice
        includedMileageDay
        images {
          data {
            attributes {
              url
            }
          }
        }
        company {
          data {
            attributes {
              name
              realTime
              selfService
              city
              contact {
                website
                latitude
                longitude
              }
            }
          }
        }
      }
    }
    meta {
      pagination {
        pageCount
        page
        pageSize
        total
      }
    }
  }
}
`

export const GET_CLASS_FILTERS = gql` 
query getClasses($classesInput:ClassFiltersInput) {
  classes(filters:$classesInput) {
    data {
      attributes {
        category {
          data {
            attributes {
              name
            }
          }
        }
        type
        rates {
          price
        }
        fromPrice
        company {
          data {
            attributes {
              realTime
              selfService
              city
            }
          }
        }
      }
    }
  }
}
`

export const GET_COMPANIES = gql`
query getCompanies($companiesInput:CompanyFiltersInput, $pagination:PaginationArg) {
  companies(filters: $companiesInput, pagination: $pagination) {
    data {
      attributes {
        name
        city
        realTime
        selfService
        contact {
          postalCode
          countryCode
          phone
          street
          website
          houseNumber	
          country
          city          
          latitude
          longitude
        }
        logo {
          data {
            attributes {
              alternativeText
              caption
              url
            }
          }
        }
      }
    }
    meta {
      pagination {
        pageCount
      }
    }
  }
}`

export const GET_COMPANY_FILTERS = gql`
  query getCompanies {
    companies {
      data {
        attributes {
          city
          realTime
          selfService
        }
      }
    }
  }
`