import React from 'react'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import axios from 'axios';
import { DepositExtra } from '../../../interfaces/Extras';

interface Contact {
    website?: string,
    phone?: string,
    postalCode?: string,
    city?: string,
    houseNumber?: string,
    street?: string,
    country?: string,
    countryCode?: string,
    latitude?: string,
    longitude?: string,
    email: string
}

interface Company {
    name: string,
    contact: Contact
}

interface ReservationForm {
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    phone: string | null,
    product: string,
    pickupLocation: string,
    pickupDate: string,
    returnDate: string,
    totalPrice: number | string,
    depositExtra: DepositExtra | null,
    company: Company | null
}

const CheckoutForm = (props: any) => {
    const {
        open,
        setOpen,
        product,
        location,
        pickupDate,
        returnDate,
        totalPrice,
        depositExtra,
        company
    } = props;

    const [state, setState] = React.useState<ReservationForm>({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        product,
        pickupLocation: location,
        pickupDate,
        returnDate,
        totalPrice,
        depositExtra,
        company
    })

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => {
            return {
                ...prevState,
                [event.target.name]: (event.target as HTMLInputElement).value
            }
        });
    };

    const handleSubmit = async () => {
        // Return if not all fields are field in
        if ((state.firstName || state.lastName || state.email || state.phone) === null) {
            alert('Niet alle velden ingevuld');
            return;
        }

        await axios.post(`${process.env.GATSBY_STRAPI_API_URL}/api/bookings`, {
            "data": {
                "firstName": state.firstName,
                "lastName": state.lastName,
                "email": state.email,
                "phone": state.phone,
                "pickupDate": new Date(state.pickupDate).toLocaleDateString('nl-NL'),
                "returnDate": new Date(state.returnDate).toLocaleDateString('nl-NL'),
                "totalPrice": state.totalPrice,
                "pickupLocation": state.pickupLocation,
                "product": state.product,
                "companyEmail": state.company?.contact?.email,
                "companyName": state.company?.name,
                "depositExtra": {
                    "totalPrice": state.depositExtra?.calcPrice,
                    "deductible": state.depositExtra?.deductible,
                    "deposit": state.depositExtra?.deposit,
                    "type": state.depositExtra?.type
                },
                "company": {
                    "name": state.company?.name,
                    "email": state.company?.contact?.email,
                    "phone": state.company?.contact?.phone,
                    "countryCode": state.company?.contact?.countryCode,
                    "street": state.company?.contact?.street,
                    "houseNumber": state.company?.contact?.houseNumber,
                    "postalCode": state.company?.contact?.postalCode,
                    "city": state.company?.contact?.city,
                    "country": state.company?.contact?.country
                }
            }
        })
            .then((res) => {
                console.log(res);
                alert('Succes! We kunnen nu een reservering maken met de doorgestuurde gegevens. We sturen je nu terug naar de homepage.')
                if (typeof window !== undefined) {
                    window.location.href = '/'
                }
            })
            .catch((err) => {
                console.log(err)
                alert('Iets ging fout.')
            })

        console.log(state)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Maak uw reservering compleet</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Vul uw gegevens in om uw aanvraag te bevestigen. Geen zorgen, u kunt altijd gratis annuleren.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    label="Voornaam"
                    type="text"
                    required
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                />

                <TextField
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    label="Achternaam"
                    type="text"
                    required
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                />

                <TextField
                    margin="dense"
                    id="email"
                    name="email"
                    label="E-mail"
                    type="email"
                    required
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                />

                <TextField
                    margin="dense"
                    id="phone"
                    name="phone"
                    label="Telefoon"
                    type="phone"
                    fullWidth
                    required
                    variant="outlined"
                    onChange={handleChange}
                />
                {/* TODO: Add Validation + Other Fields (TBD) */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuleer</Button>
                <Button onClick={handleSubmit}>Indienen</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CheckoutForm;