import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import { Container } from '@mui/system';
const NotFoundPage = () => (
    <Layout>
        <Container>
            <h1>Pagina niet gevonden</h1>
            <Link to='/' className='primary-button'>Keer terug naar home</Link>
        </Container>
    </Layout>
);

export default NotFoundPage;
