export const calculateBasePrice = (days: number, prices: Prices, pickupDate: Date) => {

    let price = 99;
    if (prices) {
        if (days >= 30) {
            price = prices.month
        } else if (days >= 7) {
            price = prices.week
        } else if (days === 2 && (new Date(pickupDate).getDay() === 6)) {
            price = prices.weekend
        } else {
            price = prices.day
        }
    }

    return (
        days * price
    )
}

interface Prices {
    day: number,
    weekend: number,
    week: number,
    month: number,
}

export const calculateIncludedMileage = (days: number, mileage: Mileage, pickupDate: Date) => {
    let includedMileage = 999;
    
    if (mileage) {
        if (days >= 7) {
            includedMileage = mileage.includedWeek;
        } else if (days === 2 && (new Date(pickupDate).getDay() === 6)) {
            includedMileage = mileage.includedWeekend;
        } else {
            includedMileage = mileage.includedDay;
        }
    }

    return days * includedMileage
}

interface Mileage {
    includedDay: number,
    includedWeekend: number,
    includedWeek: number,
    priceAdditionalKm: number,
}