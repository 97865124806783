import * as React from "react"
import { useEffect, useState } from "react";
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/styles.scss"
import Navbar from "./navigation/Navbar";
import Footer from "./navigation/Footer";

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
