import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import PickupPicker from "./PickupPicker";
import ReturnPicker from "./ReturnPicker";
import CreateBookingLayer from "../datalayers/CreateBooking";
import add from 'date-fns/add'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes'
import axios from "axios";

interface Options {
    day: string,
    week: string,
    month: string,
    year: string,
    hour: string,
    minute: string
}

const options: Options = {
    day: "numeric",
    week: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
}

const today = add(roundToNearestMinutes(new Date(), { nearestTo: 30 } ), {
    days: 1
});

const tomorrow = add(roundToNearestMinutes(new Date(), { nearestTo: 30}), {
    days: 2
})

// TODO: Add Calculated Price and Send to Database
const CheckoutForm = ({ contact, companyName, displayName }) => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [formSubmitted, setFormSubmitted] = useState(false)
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(tomorrow)

    console.log('startDate', startDate)
    console.log('endDate', endDate)

    const onSubmit = async (data) => {
        if (startDate >= endDate) {
            alert('Controleer de ophaal- en retourtijd');
            return;
        }

        setFormSubmitted(true)
        // if (!executeRecaptcha) {
        //     alert('noRecaptcha executed')
        //     return;
        // }

        CreateBookingLayer(data);

        // const token = await executeRecaptcha('submit')

        // const submitData = JSON.stringify({ data, token })

        await axios.post('https://rentvisie-broker-cms.herokuapp.com/api/quotes', {
            "data": {
                "firstName": data.firstName,
                "lastName": data.lastName,
                "vehicleClass": data.vehicleClass,
                "phone": data.phone,
                "email": data.email,
                "requestType": data.requestType,
                "pickupDate": startDate.toLocaleDateString('nl-NL'),
                "returnDate": endDate.toLocaleDateString('nl-NL'),
                "message": data.message,
                "companyName": data.companyName,
                "companyEmail": data.companyEmail
            }
        }).then((res) => console.log(res))
        .catch((err) => console.log(err))

       
    };

    return (
        !formSubmitted
            ? (
                <form onSubmit={handleSubmit(onSubmit)} className='form-wrapper'>
                    <div style={{marginBottom:10}}>
                        <select {...register("requestType")}>
                            <option value="offerte">Offerte Opvragen</option>
                            <option value="reservering">Reserveringsaanvraag Indienen</option>
                        </select>
                    </div>
                    <input
                        type="hidden"
                        value={displayName}
                        {...register("vehicleClass")}
                    >
                    </input>
                    <input
                        type="hidden"
                        value={contact.email}
                        {...register("companyEmail")}
                    >
                    </input>
                    <input
                        type="hidden"
                        value={companyName}
                        {...register("companyName")}
                    ></input>
                    <input
                        type="hidden"
                        value={startDate.toLocaleDateString('nl-NL')}
                        {...register("pickupDate")}
                    ></input>
                    <input
                        type="hidden"
                        value={endDate.toLocaleDateString('nl-NL')}
                        {...register("returnDate")}
                    ></input>
                    <label>Ophaaldatum & Tijd</label>
                    <PickupPicker startDate={startDate} setStartDate={setStartDate} />

                    <label>Retourdatum & Tijd</label>
                    <ReturnPicker endDate={endDate} setEndDate={setEndDate} startDate={startDate} />

                    <label>Voornaam</label>
                    <input
                        type="text"
                        placeholder="Voornaam"
                        {...register("firstName", { required: true, maxLength: 100 })}
                    />
                    {errors.firstName && <p>Controleer dit veld</p>}

                    <label>Achternaam</label>
                    <input
                        type="text"
                        placeholder="Achternaam"
                        {...register("lastName", { required: true, maxLength: 100 })}
                    />
                    {errors.lastName && <p>Controleer dit veld</p>}

                    <label>Telefoon</label>
                    <input
                        type="tel"
                        placeholder="Telefoonnummer"
                        {...register("phone", {
                            required: true,
                            maxLength: 12,
                            minLength: 10
                        })}
                    />
                    {errors.phone && <p>Controleer dit veld</p>}

                    <label>E-mail</label>
                    <input
                        type="email"
                        placeholder="E-mail"
                        {...register("email", {
                            required: true,
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                    />
                    {errors.email && <p>Controleer dit veld</p>}
                    

                    <label>Bericht</label>
                    <textarea
                        placeholder="Eventueel een leuk berichtje of toelichting"
                        rows={4}
                        {...register("message")}
                    />
                    {errors.message && <p>Controleer dit veld</p>}

                    <input type="submit" value="Indienen" />
                </form>
            )
            : <h3>Wij hebben uw contactverzoek in goede orde ontvangen.</h3>
    );
}

export default CheckoutForm;