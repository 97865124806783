import { Container } from "@mui/system";

export const ProductInfo = ({ goodInfo, importantInfo }) => (

    <div>
        {
            ((importantInfo && importantInfo.length > 0) || (goodInfo && goodInfo.length > 0)) &&
            <Container>
                <div className='product-info__wrapper'>
                    {importantInfo && importantInfo.length > 0 &&
                        importantInfo.map((item) => (
                            <div key={item.strapi_id}>
                                <span className="description-label">{item.label}</span>
                                <p className="description">{item.description}</p>
                            </div>
                        ))
                    }
                    {goodInfo && goodInfo.length > 0 &&
                        goodInfo.map((item) => (
                            <div key={item.strapi_id}>
                                <span className="description-label">{item.label}</span>
                                <p className="description">{item.description}</p>
                            </div>
                        ))}
                </div>
            </Container>
        }
    </div>
)



