import React from 'react';
import { Link } from 'gatsby';
import Container from '@mui/material/Container';
import Faq from '../components/homepage/Faq';
import { GetCategories } from '../components/GetCategories';
import Cars from '../images/cars.png'
import RentalCompanies from '../images/car-rental-companies.png'
import Layout from '../components/layout';
import SearchForm from '../components/search/SearchForm';

export function Index() {
  return (
    <Layout>
      <div className='home-hero'>
        <div className='home-hero__title'>
          <h1>Huur de voordeligste Auto's en Busjes</h1>
          <h2>Vind jouw huurauto</h2>
        </div>
        <div className='home-hero__cars'>
          <img src={Cars} alt='Rental Cars' />
        </div>
      </div>
      <SearchForm />
      <GetCategories />
      <Container>
        <div className='company-wrapper'>
          <div className='company-content'>
            <h2>Vind het verhuurbedrijf bij jou in de buurt</h2>
            <p style={{ marginBottom: '2rem' }}>Maak kennis met alle autoverhuurders in de buurt en hun unieke acties. Filter op jouw locatie en vind alle autoverhuurbedrijven met hun huuraanbod in de buurt.</p>
            <Link to='/autoverhuurders' className='primary-button button'>Alle bedrijven</Link>
          </div>
          <div className='company-image'>
            <img src={RentalCompanies} alt='car rental companies' />
          </div>
        </div>
      </Container>
      <Faq />
    </Layout>
  );
}

export default Index;
