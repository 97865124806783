export const HeroSection = (props) => {
    const {
        title,
        companyName
    } = props

    return (
        <div className='hero-section'>
            <h1>{title}</h1>
            {companyName &&
                <h2>by {companyName}</h2>
            }
        </div>
    )
}