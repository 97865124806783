import { Link } from "gatsby";

const cities = [
    'Amsterdam',
    'Hilversum',
    'Hoorn',
    'Zwaag',
    'Purmerend',
    'Schiphol',
    'Utrecht',
    'Wageningen',
    'Den Haag',
    'Zaandam'
]

const CityLinks = ({category}) => (
    <div className="citylinks-container">
        <h2>Populaire Plaatsen</h2>
        <div className="citylinks-wrapper">
            <div className="citylinks-grid">
                {cities.map((city) => (
                    <div>
                        <Link to={`/huren/${category.toLowerCase().replace(/ +/g, '-')}/${city.toLowerCase().replace(/ +/g, '-')}`}>{city}</Link>
                    </div>
                ))}
            </div>
        </div>
    </div>
)

export default CityLinks;