import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout';
import '../../styles/reservation-portal/search-criteria.scss';
import differenceInDays from 'date-fns/differenceInDays'
import { PageProps } from 'gatsby';
import { GetVehicles } from '../../components/search/getVehicles';
import SearchForm from '../../components/search/SearchForm';
import { Button } from '@mui/material';

const VehicleSelectPage = (props: PageProps) => {
    const query = new URLSearchParams(props.location.search);
    const location = query.get("locatie") || 'Amsterdam';
    const category = query.get("categorie");
    const pickupDate = query.get("ophaaldatum");
    const returnDate = query.get("retourdatum");
    const splitLocation = location.split(',');
    const city = splitLocation[0];
    const [days, setDays] = useState<Number>(1)
    const [showForm, setShowForm] = useState(false)

    useEffect(() => {
        setDays(differenceInDays(new Date(returnDate), new Date(pickupDate)) + 1)
    }, [pickupDate, returnDate])

    return (
        <Layout>
            <section>
                <Container>
                    <h1>Kies een voertuig op basis van uw zoekcriteria
                    </h1>
                    
                        
                    <div className='search-criteria-wrapper'>
                        <div>periode</div>
                        <div>
                            {new Date(pickupDate).toLocaleDateString('nl-NL')} tot {new Date(returnDate).toLocaleDateString('nl-NL')} ({days}
                            {days === 1
                                ? ' dag'
                                : ' dagen'
                            })
                        </div>

                        <div>categorie</div>
                        <div>{category}</div>

                        <div>locatie</div>
                        <div>{city}</div>


                    </div>
                    <Button variant='text' size="small" style={{marginTop:10}} onClick={() => {setShowForm(!showForm)}}>{!showForm 
                        ? 'Zoekcriteria wijzigen'
                        : 'Zoeken inklappen' 
                        }
                    </Button>
                </Container>
                {showForm && <SearchForm />}
                <Container>

                    <GetVehicles
                        category={category}
                        location={location}
                        days={days}
                        pickupDate={pickupDate}
                        returnDate={returnDate}
                    />
                </Container>
            </section>
        </Layout>
    )
}

export default VehicleSelectPage;