import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function PriceTable({ rates }) {
  //sort rates in ascending order for order (hours, day, 3t6days, weekend, week, month and so on ) and on price within the order...
  rates.sort(function (a, b) {
    return a.order - b.order || a.price - b.price;
  });
  console.log('rates in priceTabel after sort', rates)
  return (
    <TableContainer sx={{ fontSize: 8 }} component={Paper}>
      <Table aria-label="price table">
        <TableHead>
          <TableRow>
            <TableCell>Periode</TableCell>
            <TableCell align="left">Prijs / Dag</TableCell>
            <TableCell align="left">Vrije KM / Dag</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rates.map((rate) => (
            <TableRow
              key={rate.strapi_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                {rate.period === 'hour' && 'Uur'}
                {rate.period === 'halfday' && 'Dagdeel'}
                {rate.period === 'day' && 'Dag'}
                {rate.period === 'weekend' && 'Weekend'}
                {rate.period === 'threetosixdays' && '3 tot 6 dagen'}
                {rate.period === 'week' && 'Week'}
                {rate.period === 'month' && 'Maand'}

              </TableCell>

              <TableCell>€ {rate.price.toFixed(2).toString().replace(".", ",")}</TableCell>
              <TableCell>{rate.includedMileage}km</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* Add Price extra KM */}
    </TableContainer>
  );
}
