import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckoutForm from "./CheckoutForm";

export default function CheckoutDialog({ handleClose, open, displayName, contact, companyName }) {
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle><span style={{fontWeight:600}}>{displayName} bij {companyName}</span> <br></br>Vraag een offerte op of dien een reserveringsaanvraag in</DialogTitle>
                <DialogContent>
                    <div style={{ padding: "10px 0" }}>
                        {/* TODO: Add Prices */}
                        <CheckoutForm contact={contact} companyName={companyName} displayName={displayName} />
                        <DialogActions>
                            <Button onClick={handleClose}>Sluiten</Button>
                        </DialogActions>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
