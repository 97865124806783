import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PickupPicker = ({ startDate, setStartDate }) => {

    return (
        <DatePicker
            selected={startDate}
            onChange={(date) => { setStartDate(date) }}
            dateFormat="dd-MM-yyyy"
            highlightDates={[(new Date())]}
            minDate={new Date()}
        />
    );
};

export default PickupPicker