import { Container } from '@mui/material'
import React from 'react'
import { Button } from '@mui/material';
import compareAsc from 'date-fns/compareAsc';
import sub from 'date-fns/sub'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import HeroSearchAddress from './form/search'
import HeroDatepickers from './form/datepickers';
import PassengerVan from '../../images/svg/passengerVan.svg';
import DeliveryVan from '../../images/svg/deliveryVan.svg';
import Car from '../../images/svg/car.svg';

const SearchForm = () => {
  const [category, setCategory] = React.useState('bestelbus');
  const [location, setLocation] = React.useState<string | null>(null);
  const [pickupDate, setPickupDate] = React.useState<Date | null>(null)
  const [returnDate, setReturnDate] = React.useState<Date | null>(null)
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategory((event.target as HTMLInputElement).value);
  };

  const handleSearch = () => {
    if (!(location && pickupDate && returnDate)) {
      setErrorMessage('Vul alle velden in');
      return;
    }

    if (pickupDate && returnDate) {
      const checkPickupDate = compareAsc(sub(new Date(), {days: 1}), pickupDate)
      const checkDates = compareAsc(pickupDate, returnDate)
      if (checkPickupDate > 0) {
        setErrorMessage('Ophaaldatum is niet correct')
        return;
      }

      if (checkDates > 0) {
        setErrorMessage('Retourdatum is niet correct')
        return;
      }
    }

    if (typeof window !== undefined) {
      window.location.replace(`/huren/voertuig-kiezen?locatie=${location}&ophaaldatum=${pickupDate?.toUTCString()}&retourdatum=${returnDate?.toUTCString()}&categorie=${category}`);
      return;
    }

  }
  return (
    <Container>
      <div style={{ margin: '50px 0' }}>

        <h2>Vergelijk het aanbod van onze partners en reserveer direct</h2>
        <div className='hero-form'>
          <div className='hero-form-wrapper'>

            <div className='hero-form-categories'>
              <RadioGroup
                row
                aria-labelledby="category-group"
                name="categorie"
                value={category}
                onChange={handleChange}
              >
                <FormControlLabel value="Personenbus" control={<Radio />} label={<div><img src={PassengerVan} alt='personenbus' style={{ width: 32 }} /> Personenbus</div>} />
                <FormControlLabel value="Auto" control={<Radio />} label={<div><img src={Car} alt='auto' style={{ width: 32 }} /> Personenauto</div>} />
                <FormControlLabel value="Bestelbus" control={<Radio />} label={<div><img src={DeliveryVan} alt='bestelbus' style={{ width: 32 }} /> Bestelbus</div>} />
              </RadioGroup>
            </div>
            <div className='hero-form-search'>
              <HeroSearchAddress setLocation={setLocation} />
              <HeroDatepickers
                pickupDate={pickupDate}
                returnDate={returnDate}
                errorMessage={errorMessage}
                setPickupDate={setPickupDate}
                setReturnDate={setReturnDate}
                setErrorMessage={setErrorMessage}
              />

              <div className='hero-form-search__button'>
                <button className='primary-button' onClick={handleSearch} style={{width:'100%'}}>Zoeken</button>
                {errorMessage &&
                  <p>{errorMessage}</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </Container>
  )
}

export default SearchForm