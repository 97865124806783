import { useEffect, useState } from "react"

export const RealtimeFilter = ({ data, setCheckedRealTime }) => {
    const [checked, setChecked] = useState([])
    // reusable function for companies and classes
    let realtimeItems = {}
    if (data.companies !== undefined) {
        realtimeItems = data.companies.data.map(item => item.attributes.realTime)
    }
    if (data.classes !== undefined) {
        realtimeItems = data.classes.data.map(item => item.attributes.company.data.attributes.realTime)
    }
    const realtimeSet = new Set(realtimeItems)
    const realtimeArray = Array.from(realtimeSet)

    const handleCheckRealTime = (event) => {
        let realTimeList = [...checked]
        if (event.target.checked) {
            realTimeList = [...checked, event.target.value]
        } else {
            realTimeList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(realTimeList)
    };

    useEffect(() => {
        setCheckedRealTime(checked)
    }, [checked])

    return (
            <>
        <p className='description-label'>Real Time:</p>
        {realtimeArray.map((item, index) => (
            <div key={index} className='filter-checkbox-row'>
                <label>
                    <input
                        type="checkbox"
                        name="type"
                        value={item}
                        onChange={handleCheckRealTime}
                    // onClick={(e) => { operations.handleFilter("type", e.target.value) }} 
                    /> {item === "No" ? "Nee" : "Ja"}
                </label>
            </div>
        ))}
    </>
    )
}