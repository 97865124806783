import React from 'react'
import { usePlacesWidget } from "react-google-autocomplete";
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const HeroSearchAddress = (props: any) => {
    const {
        setLocation
    } = props

    const { ref: materialRef } = usePlacesWidget({
        apiKey: process.env.GATSBY_GOOGLEMAPS_API,
        inputAutocompleteValue: "city",
        onPlaceSelected: (selected) => {
            setLocation(selected.formatted_address)
        },
        options: {
            types: ["address"],
            fields: ["formatted_address"],
            componentRestrictions: { country: "nl" }
        }
    });

    return (
        <div className='hero-form-search__location'>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    id="outlined-required"
                    label="Locatie"
                    placeholder="Selecteer jouw Locatie"
                    inputRef={materialRef}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationOnIcon style={{ color: '#777', marginRight: '4px' }} />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        </div>
    )
}

export default HeroSearchAddress