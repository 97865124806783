import { Button, TextField } from '@mui/material'
import { Container } from '@mui/system'
import axios from 'axios'
import React from 'react'
import Layout from '../components/layout'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface OnboardingForm {
    name: string,
    phone: string,
    email: string
}

interface Company {
    name: string,
    selfService: boolean,
    realTime: boolean,
    city: string,
    shortDescription: string,
    longDescription: string,
    bovag: boolean,
    keybox: boolean,
    contact: Contact
}

interface Contact {
    website: string,
    phone: string,
    postalCode: string,
    city: string,
    houseNumber: string,
    street: string,
    country: string,
    countryCode?: string,
    latitude?: string,
    longitude?: string,
    email: string
}

const SignUp = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    const [onboardingForm, setOnboardingForm] = React.useState<OnboardingForm>({})
    const [loading, setLoading] = React.useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOnboardingForm(prevState => {
            return {
                ...prevState,
                [event.target.name]: (event.target as HTMLInputElement).value
            }
        });
    };

    const submitOnboardingForm = async () => {

        setLoading(true)

        if (!executeRecaptcha) {
            alert('noRecaptcha executed')
            return;
        }
        const token = await executeRecaptcha('submit')

        if (!onboardingForm.name || onboardingForm.name.length < 3) {
            alert('Controleer uw bedrijfsnaam');
            return;
        }

        if (!onboardingForm.phone || onboardingForm.phone.length < 10) {
            alert('Controleer uw telefoonnummer');
            return;
        }

        if (!onboardingForm.email || onboardingForm.email.length < 8) {
            alert('Controleer uw e-mail');
            return;
        }

        // publishedAt null to create in Draft
        // axios.post(`${process.env.GATSBY_STRAPI_API_URL}/api/companies`, {
        // TODO: Publish API Server
        axios.post(`http://localhost:3000/companies`, {
            data: {
                "name": onboardingForm.name,
                "contact": {
                    "phone": onboardingForm.phone,
                    "email": onboardingForm.email
                },
                "publishedAt": null,
                "token": token
            }
        }, {
            headers: {
                'content-type': 'application/json'
            }
        })
            .then((res) => {
                console.log(res);
                alert('Succes! Uw aanmelding wordt door ons gecontroleerd. U ontvangt per e-mail instructies voor vervolgstappen.')
                if (typeof window !== undefined) {
                    window.location.href = '/'
                }
            })
            .catch((err) => {
                console.log(err)
                alert('Iets ging fout.')
            })



    }

    return (
        <Layout>
            <Container>
                <h1>Meld jouw verhuurbedrijf aan in 1 minuut</h1>
                <form>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Bedrijfsnaam"
                        type="text"
                        required
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="phone"
                        name="phone"
                        label="Telefoonnummer"
                        type="phone"
                        required
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        name="email"
                        label="E-mail"
                        type="email"
                        required
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                    />
                    <Button variant='contained' onClick={submitOnboardingForm} fullWidth style={{ marginTop: 12 }}>Bedrijf aanmelden</Button>
                </form>
                <p>Geen zorgen, aanmelden is geheel gratis.</p>
            </Container>
        </Layout>
    )
}

export default SignUp