import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const ReturnPicker = ({endDate, setEndDate, startDate}) => {

    return (
        <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd-MM-yyyy"
            highlightDates={[startDate]}
            minDate={startDate}
        />
    );
};

export default ReturnPicker