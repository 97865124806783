import { Container } from "@mui/system"
import { useState } from "react"

export const ProductOptions = ({ holdingOptions, mileageOptions, optionalOptions, insuranceOptions }) => {
    const [showMore, setShowMore] = useState(false)

    const handleShowMore = () => {
        if (!showMore) {
            setShowMore(true)
        } else {
            setShowMore(false)
        }
    }
    return (
        <div className="product-options__wrapper">
            <Container>
                <h2>PERSONALISEER JOUW VOERTUIG</h2>
                <span className='description-label'>Beschikbare Opties</span>
                {holdingOptions.length > 0 &&
                    <div>
                        <h3>Borg & Eigen Risico</h3>
                        <div className="options-grid">
                            {holdingOptions.map((option) => (
                                <div className="option">
                                    <div className="option-grid">
                                        <div>
                                            <span className='description-label'>
                                                Type
                                            </span>
                                            <p className="description">
                                                {option.type === 'highRisk'
                                                    && 'Risicovol'
                                                }
                                                {option.type === 'mediumRisk'
                                                    && 'Gemiddeld Risico'
                                                }
                                                {option.type === 'lowRisk'
                                                    && 'Weinig Risico'
                                                }
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Eigen Risico
                                            </span>
                                            <p className='description'>
                                                € {option.deductible}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Borg
                                            </span>
                                            <p className='description'>
                                                € {option.deposit}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Prijs
                                            </span>
                                            <p className="description">
                                                € {option.price} /
                                                {
                                                    option.priceCalculation === 'perday'
                                                        ? ' dag'
                                                        : ' trip'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }

                {mileageOptions.length > 0 &&
                    <div>
                        <h3>Kilometerpakketten</h3>
                        <div className="options-grid">
                            {mileageOptions.map((option) => (
                                <div className="option">
                                    <div className="option-grid">
                                        <div>
                                            <span className="description-label">
                                                Pakket
                                            </span>
                                            <p className='description'>
                                                {option.title}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Beschrijving
                                            </span>
                                            <p className='description'>
                                                {option.description}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Inbegrepen KM / Dag
                                            </span>
                                            <p className='description'>
                                                {option.includedMileage}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Prijs
                                            </span>
                                            <p className="description">
                                                € {option.price} /
                                                {
                                                    option.period === 'day'
                                                        ? ' dag'
                                                        : ' trip'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {(showMore && insuranceOptions.length !== 0) &&
                    <div>
                        <h3>Extra Verzekeringen</h3>
                        <div className="options-grid">
                            {insuranceOptions.map((option) => (
                                <div className="option">
                                    <div className="option-grid">
                                        <div>
                                            <span className="description-label">
                                                Naam
                                            </span>
                                            <p className='description'>
                                                {option.title}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Beschrijving
                                            </span>
                                            <p className='description'>
                                                {option.description}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Prijs
                                            </span>
                                            <p className='description'>
                                                € {option.price} / {
                                                    option.priceCalculation === 'perday'
                                                        ? ' dag'
                                                        : ' trip'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                } {(showMore && optionalOptions.length > 0) &&
                    <div>
                        <h3>Overige Opties</h3>
                        <div className="options-grid">
                            {optionalOptions.map((option) => (
                                <div className="option">
                                    <div className="option-grid">
                                        <div>
                                            <span className="description-label">
                                                Naam
                                            </span>
                                            <p className='description'>
                                                {option.title}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Beschrijving
                                            </span>
                                            <p className='description'>
                                                {option.description}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Prijs
                                            </span>
                                            <p className='description'>
                                                € {option.price} / {
                                                    option.priceCalculation === 'perday'
                                                        ? ' dag'
                                                        : ' trip'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {
                    (insuranceOptions.length > 0 || optionalOptions.length > 0) &&
                    <button style={{marginTop:'1rem'}} className="secondary-button" onClick={handleShowMore}>{!showMore ? 'Meer' : 'Minder'}</button>
                }


            </Container>
        </div>
    )
}