import { Link } from "gatsby";
import Container from '@mui/material/Container';
import { HeroSection } from "../components/hero";
import { ProductHighlights } from "../components/product/ProductHighlights";
import ProductImageCarousel from "../components/carousel";
import { ProductInfo } from "../components/product/ProductInfo";
import { CompanyInfo } from "../components/company/company-info";
import { ProductOptions } from "../components/product/ProductOptions";
import { useState } from "react";
import CheckoutDialog from "../components/product/Checkout";
import { PriceDialog } from "../components/product/PriceDialog";
import Layout from "../components/layout";

export function Product({ pageContext }) {
  const [open, setOpen] = useState(false)
  const [openPrices, setOpenPrices] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePrices = () => {
    setOpenPrices(false)
  }

  const {
    category,
    contact,
    displayName,
    companyName,
    images,
    fromPrice,
    rates,
    goodInfo,
    importantInfo,
    holdingAndDeposit,
    mileageOptions,
    optionalOptions,
    insuranceOptions,
    longDescription,
    opening,
    realTime,
    selfService,
    shortDescription,
    logo } = pageContext
  console.log(pageContext)
  return (
    <Layout>
      <HeroSection title={displayName} companyName={companyName}></HeroSection>
      <Container>
        <div className='breadcrumb'>
          <Link to='/'>Home</Link> {`>`} <Link to='/huren'>huren</Link> {`>`} <Link to={`/huren/${category}`}>{category}</Link> {`>`} {displayName} {`bij`} {companyName}
        </div>
        <div className='row-50'>
          <div className="product-carousel">
            <ProductImageCarousel images={images} />
          </div>
          <ProductHighlights
            contact={contact}
            companyName={companyName}
            fromPrice={fromPrice}
            selfService={selfService}
            realTime={realTime}
            setOpen={setOpen}
            setOpenPrices={setOpenPrices}
          />
        </div>
        <PriceDialog
          open={openPrices}
          rates={rates}
          handleClose={handleClosePrices}
          fromPrice={fromPrice}
        />
        <CheckoutDialog
          displayName={displayName}
          contact={contact}
          companyName={companyName}
          handleClose={handleClose}
          open={open}>
        </CheckoutDialog>
      </Container>
      {/* TODO: ADD MILEAGEPACKAGEOPTIONS / INSURANCEOPTIONS / OTHEROPTIONS */}
      <ProductInfo
        goodInfo={goodInfo}
        importantInfo={importantInfo}
      />
      <ProductOptions
        holdingOptions={holdingAndDeposit}
        mileageOptions={mileageOptions}
        optionalOptions={optionalOptions}
        insuranceOptions={insuranceOptions}
      />
      <CompanyInfo
        contact={contact}
        opening={opening}
        shortDescription={shortDescription}
        longDescription={longDescription}
        logo={logo}
      />
    </Layout>
  );
}

export default Product;
