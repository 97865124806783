import React from 'react'
import { nl } from 'date-fns/locale';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const HeroDatepickers = (props: any) => {
    const {
        pickupDate,
        setPickupDate,
        returnDate,
        setReturnDate,
        errorMessage,
        setErrorMessage
     } = props
    return (
        <div className='hero-form-search__locations'>
            <div className='hero-form-search__pickup' style={{width:'100%'}}>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                    <DatePicker
                        label="Ophaaldatum"
                        value={pickupDate}
                        onChange={(newDate: Date | null) => {
                            setPickupDate(newDate);
                            setErrorMessage('')
                        }}
                        className='mui-datepicker'
                        minDate={new Date()}
                        InputAdornmentProps={{
                            position: 'start',
                            sx: {
                                width: 24,
                                height: 24,
                                color: '#001869 !important',
                                marginLeft: '4px',
                            }
                        }}
                        renderInput={(params: any) => <TextField {...params} placeholder='dd-mm-yyyy' />}
                    />
                </LocalizationProvider>

            </div>
            <div className='hero-form-search__return' style={{width:'100%'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                    <DatePicker
                        label="Retourdatum"
                        value={returnDate}
                        onChange={(newDate: Date | null) => {
                            setReturnDate(newDate);
                            setErrorMessage('')
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                            sx: {
                                width: 24,
                                height: 24,
                                color: '#001869 !important',
                                marginLeft: '4px',
                            }
                        }}
                        className='mui-datepicker'
                        minDate={pickupDate}
                        renderInput={(params: any) => <TextField {...params} placeholder='dd-mm-yyyy' />}
                    />
                </LocalizationProvider>
            </div>
        </div>
    )
}

export default HeroDatepickers