import React, { useState, useEffect } from 'react';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

export default function Calendar({ setHours, setDays, setMultiDays, setWeekends, setWeeks, setMonths, setYears }) {
    const [value, onChange] = useState([new Date(), new Date()]);
    const MILISECONDS_TO_HOURS = 60 * 60 * 1000

    useEffect(() => {
        let differenceInMiliseconds;
        if (value != null) {
            differenceInMiliseconds = value[1] - value[0]
        }
        const hours = differenceInMiliseconds / MILISECONDS_TO_HOURS
        if (hours > 0 || hours !== undefined)
            setHours(hours)

        const dateFrom = value[0];
        const dateTo = value[1];
        const startDay = dateFrom.getDay();
        const endDay = dateTo.getDay();

        let myHours = hours;
        let weekends = 0;
        let multiDays = 0;
        if (hours === 0) {
            myHours = myHours + 0.01
        } else {
            if (hours <= 4) {
                myHours = hours;
            } else {
                const startEightDate = new Date(value[0]);
                startEightDate.setHours(8)
                startEightDate.setMinutes(0)
                startEightDate.setSeconds(0)
                differenceInMiliseconds = value[1] - startEightDate;
                myHours = differenceInMiliseconds / MILISECONDS_TO_HOURS;
            }
        }
        console.log('periode in calendar', value)

        let days = Math.ceil(myHours / 24);
        let weeks = Math.floor(days / 7);
        let months = Math.floor(days / 30);
        let years = Math.floor(days / 365);
        days -= weeks * 7;

        // Is a weekend included ?   
        if ((startDay - endDay > 1) || (endDay === 0 && days > 1)) {
            days -= 2;
            weekends++;
        }

        //corrigeer weekend als het onder deel is van een multiday periode 
        days = Math.ceil(myHours / 24);
        if (days >= 3 && days <= 6) {
            multiDays = days;
            weekends = 0;
        }
        if (years > 0) {
            multiDays = 0;
            weekends = 0;
            weeks = 0;
            months = 0;
        } else {
            if (months > 0) {
                multiDays = 0;
                weekends = 0;
                weeks = 0;
            } else {
                if (weeks > 0) {
                    multiDays = 0;
                    weekends = 0;
                }
            }
        }
        setDays(days)
        setMultiDays(multiDays)
        setWeekends(weekends)
        setWeeks(weeks)
        setMonths(months)
        setYears(years)

        console.log(' hours ', hours, ' myHours', myHours, 'days', days, 'multiDays', multiDays, 'weekends', weekends, 'weeks', weeks, 'months', months, 'years', years)
    }, [value])

    return (
        <div>
            <DateTimeRangePicker
                onChange={onChange}
                value={value}
                locale="nl-NL"
                disableClock={true}
                rangeDivider={" tot "}
                clearIcon={null}
                calendarIcon={null}
                className="datetimerange-main"
                calendarClassName="datetimerange-calendar"
                clockClassName="datetimerange-clock"
            />
        </div>
    );
}