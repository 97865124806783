import Container from '@mui/material/Container';
import { Link } from 'gatsby'
import { GetClasses } from "../components/GetClasses";
import { HeroSection } from '../components/hero';
import SeoText from '../components/landingpage/seotext';
import CityLinks from '../components/landingpage/citylinks';
import Layout from '../components/layout';

export function Landingpage({ pageContext }) {
  const { category, city, body } = pageContext
  return (
    <Layout>
      <HeroSection title={`${category.replace('-', ' ')} ${city}`}></HeroSection>
      <Container>
        <div className='breadcrumb' aria-label="breadcrumb">
          <Link to='/'>Home</Link> {`>`} <Link to='/huren'>Huren</Link> {`>`} <Link to={`/huren/${category}`}>{category}</Link> {`>`} {city}
        </div>
        <GetClasses name={category} city={city} />
        <CityLinks category={category}></CityLinks>
        <div className='additional-info-wrapper'>
          <SeoText body={body.data.body} ></SeoText>
        </div>
      </Container>
    </Layout>
  );
}

export default Landingpage;
