import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Button } from '@mui/material';
import Geocode from "react-geocode";
import { selectVehicle } from '../helperFunctions';
import { calculateBasePrice } from '../calculateFunctions';

Geocode.setApiKey(process.env.GATSBY_GOOGLEMAPS_API);
Geocode.setLanguage("en");
Geocode.setRegion("nl");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

interface Coordinates {
    lat: number,
    lng: number
}

const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '6px',
    marginTop: 24
};


const MapStep1 = ({ result, searchLocation, pickupDate, returnDate, days }) => {
    const [activeMarker, setActiveMarker] = React.useState<number | null>(null)
    const [geocodedLocation, setGeocodedLocation] = React.useState<Coordinates>({ lat: 52.35480546119518, lng: 5.63279 })

    React.useEffect(() => {
        Geocode.fromAddress(searchLocation).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setGeocodedLocation({
                    lat,
                    lng
                })
            },
            (error: any) => {
                console.error(error);
            }
        );
    }, [])

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const center = geocodedLocation



    return (
        <LoadScript
            googleMapsApiKey={`${process.env.GATSBY_GOOGLEMAPS_API}`}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
            >
                {result.data.map((location) => (
                    location.classes.map((product) => (
                        <Marker
                            key={product.id}
                            position={{
                                lat: product.lat,
                                lng: product.lon
                            }}
                            onClick={() => {
                                handleActiveMarker(product.id)
                            }}
                        >
                            {activeMarker === product.id ? (
                                <InfoWindow
                                    onCloseClick={() => setActiveMarker(null)}>
                                    <div>
                                        <h3>€ {calculateBasePrice(days, product.prices, pickupDate)}</h3>
                                        <h2>{product.displayName}</h2>
                                        <div><img src={product.images[0].url} alt={product.images[0].alternativeText} style={{ width: 200 }} /></div>
                                        <Button variant='contained' fullWidth onClick={() => selectVehicle(product.id, pickupDate, returnDate)}>
                                            Huur op deze locatie
                                        </Button>
                                    </div>
                                </InfoWindow>
                            ) : null}
                        </Marker>
                    ))
                ))
                }
                <Marker
                    key={99}
                    position={geocodedLocation}
                    onClick={() => {
                        handleActiveMarker(-1)
                    }}
                    opacity={0.4}
                >
                    {activeMarker === -1 ? (
                        <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}>
                            <div>
                                <h3>U bent hier</h3>
                            </div>
                        </InfoWindow>
                    ) : null}
                </Marker>
            </GoogleMap>
        </LoadScript>
    )
}

export default MapStep1