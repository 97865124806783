import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '6px'
};


const MapStep2 = ({ lat, lng }) => {

    const center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
    };


    return (
        <LoadScript
            googleMapsApiKey={`${process.env.GATSBY_GOOGLEMAPS_API}`}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
            >
                { /* Child components, such as markers, info windows, etc. */}
                <Marker
                    position={{
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    }}
                >
                </Marker>
            </GoogleMap>
        </LoadScript>
    )
}

export default MapStep2;