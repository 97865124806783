import Slider from '@mui/material/Slider';
import { useEffect, useState } from "react";

//IMPORTANT NOTE: CANNOT FILTER ON COMPONENTS IN GRAPHQL
//https://github.com/strapi/strapi/issues/5124 

export const SliderFilter = ({data, setPriceValues}) => {
    const pricesArray = data.classes.data.map(item => item.attributes.fromPrice)
    const minPrice = pricesArray.reduce(function(prev, curr) {
        return prev < curr ? prev : curr;
    })

    const maxPrice = pricesArray.reduce(function(prev, curr) {
        return prev > curr ? prev : curr;
    })

    const valuetext = (value) => {
        return `${value}€`
    }

    const [value, setValue] = useState<number[]>([1, maxPrice]);

    useEffect(() => {
        setPriceValues(value)
    }, [value])
    
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
      };

    return (
        <>
            <p className='description-label'>Prijs</p>
            <Slider
                getAriaLabel={() => 'Price Range'}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={0}
                max={(maxPrice + 10)}
                sx={{maxWidth:200}}
            />
        </>
    )
}