import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export function ProductImageCarousel({images}) {
    return (
        <Carousel
            showStatus={false}
            className='carousel'
            autoPlay={true}
        >
        {images.slice(0,4).map((image) => (
            <div>
                <img src={image.url} alt={image.alternativeText} title={image.caption} />
            </div>
        ))}
        </Carousel>
    );
}


export default ProductImageCarousel;
