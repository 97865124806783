import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../../styles/reservation-portal/vehicle-display.scss'
import { Button } from '@mui/material'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ScaleIcon from '@mui/icons-material/Scale';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { calculateBasePrice } from '../../components/search/calculateFunctions';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MapStep1 from './step-1/map';
import { selectVehicle } from './helperFunctions';

interface Coordinates {
    latitude: number,
    longitude: number
}


export const GetVehicles = (props: any) => {
    const [result, setResult] = useState<any>({});
    const [showMap, setShowMap] = useState(false)
    const [loading, setLoading] = useState<boolean>(false);
    const [geocodedLocation, setGeocodedLocation] = useState<Coordinates>({ latitude: 52.390788767940386, longitude: 4.853373656062221 })
    const { category, location, days, pickupDate, returnDate } = props;

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            url: `${process.env.GATSBY_STRAPI_API_URL}/api/company-search?category=${category}&radius=55000&location=${location}&pickupDate=${pickupDate}&returnDate=${returnDate}`
        })
            .then((res) => {
                setResult(res)
            })
            .catch((e) => console.log(e))
            .finally(() => {
                setLoading(false)
            })

    }, [])



    return (
        <div>
            {(result.data && result.data.length > 0) &&
                <div style={{ margin: '24px 0', display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='contained' onClick={() => setShowMap(!showMap)}>
                        <MapOutlinedIcon />
                        {showMap
                            ? ' Verberg Kaart'
                            : ' Toon op Kaart'
                        }
                    </Button>
                    {/* <Button variant='outlined' onClick={() => alert('In ontwikkeling')}>
                        Sorteer
                    </Button> */}
                </div>
            }
            {showMap &&
                <MapStep1
                    result={result}
                    searchLocation={location} 
                    pickupDate={pickupDate}
                    returnDate={returnDate}
                    days={days}
                />
            }

            {(result.data && result.data.length > 0) ?
                result.data.map((location) => (
                    location.classes.map((product) => (
                        <div key={product.id} className='results-wrapper'>
                            <div className='results-wrapper__content'>
                                <div>
                                    <img src={product.images[0].url} alt={product.images[0].alternativeText} style={{ maxWidth: '100%' }} />
                                </div>
                                <div>
                                    <h2>{product.displayName}</h2>
                                    {location.city} {Math.floor(location.distance / 1000)} km
                                    {product.specifiations &&
                                        <div className='results-wrapper__details'>
                                            <ul>
                                                {product.specifications.fuel &&
                                                    <li><LocalGasStationIcon /> <div><span style={{ fontWeight: 600 }}>Brandstof</span> <br></br>{product.specifications.fuel}</div></li>
                                                }
                                                {product.specifications.showPayload &&
                                                    <li><ScaleIcon /> <div><span style={{ fontWeight: 600 }}>Draaggewicht</span> <br></br> {product.specifications.payload}</div></li>
                                                }
                                                {product.specifications.innerSizes &&
                                                    <li><LocalShippingIcon /> <div><span style={{ fontWeight: 600 }}>Binnenmaten</span> <br></br> {product.specifications.innerSizes}</div></li>
                                                }
                                                {product.specifications.outerSizes &&
                                                    <li><LocalShippingIcon /> <div><span style={{ fontWeight: 600 }}>Buitenmaten</span> <br></br> {product.specifications.outerSizes}</div></li>
                                                }
                                                {product.specifications.seats &&
                                                    <li><AirlineSeatReclineNormalIcon /> <div><span style={{ fontWeight: 600 }}>Zitplaatsen</span>  <br></br> {product.specifications.seats}</div></li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                                <div className='results-prices'>
                                    <div className='results-prices__info'>
                                        <p>Totale huurprijs </p>
                                        <h3>€
                                            {calculateBasePrice(days, product.prices, pickupDate)}
                                        </h3>
                                        <p>Borg: € {product.prices?.deposit} + huur</p>
                                        <Button variant='contained' onClick={() => { selectVehicle(product.id, pickupDate, returnDate) }}>Selecteer</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ))
                : loading
                    ? <div style={{ marginTop: 100 }}><h3>Ogenblik geduld...</h3></div>
                    : (<div style={{ marginTop: 100 }}>
                        <h2>Niet gevonden wat u zocht?</h2>
                        <p>Pas uw zoekcriteria aan en wij gaan voor u verder zoeken.</p>
                    </div>)
            }
        </div>
    )


}

