import '../styles/styles.scss';
import { Link } from "gatsby"
import Container from '@mui/material/Container';
import { HeroSection } from '../components/hero';
import { useQuery } from '@apollo/client';
import { GET_COMPANIES } from '../graphql/Schema';
import { useState, useEffect } from 'react';
import { QueryCompanyLocations } from "../components/filters/locations";
import { QueryCompanyFilters } from '../components/filters/QueryFilters';
import { Map } from "../components/map/map";
import MapIcon from "../images/icons/map-icon.png";
import CloseIcon from "../images/icons/close-icon.png";
import Layout from '../components/layout';

/* eslint-disable-next-line */

export function Companies() {
  const [checkedRealTime, setCheckedRealTime] = useState([]);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [checkedSelfService, setCheckedSelfService] = useState([]);
  const [pageLimit, setPageLimit] = useState(50);
  const [displayMap, setDisplayMap] = useState(false)

  const compind = true

  const FETCHMOREITEMS = 50;

  let input = {}
  const refetchFunction = () => {
    if (checkedLocations && checkedLocations.length > 0 && checkedLocations.toString()) {
      input.city = { in: checkedLocations }
    }
    if (checkedSelfService.length > 0) {
      input.selfService = { in: checkedSelfService }
    }
    if (checkedRealTime.length > 0) {
      input.realTime = { in: checkedRealTime }
    }
    const refetchObject = {
      companiesInput: input,
      pagination: { limit: pageLimit }
    }
    refetch(refetchObject)
  }

  useEffect(() => {
    refetchFunction()
  }, [pageLimit])


  const mapHandler = () => {
    if (displayMap) {
      setDisplayMap(false)
    } else {
      setDisplayMap(true)
    }
  }

  const { loading, error, data, refetch } = useQuery(GET_COMPANIES, {
    variables: {
      companiesInput: input,
      // sort: sort,
      pagination: { limit: pageLimit },
    }
  })

  if (loading) return (
    <Layout>
      <HeroSection title='Autoverhuurders'></HeroSection>
      <Container>
        <div className='breadcrumb'>
          <Link to='/'>Home</Link> {`>`} Autoverhuurders
        </div>
        <div className="filter-row">
          {/* TODO: ADD COMPANIES FILTER TO SCHEMA => CREATE NEW QUERYLOCATIONS COMPONENT FOR LOCATIONS  */}
          <div className="location-search">
            {console.log('checkedLocations in autoverhuurders.tsx', checkedLocations)}
            <QueryCompanyLocations setCheckedLocations={setCheckedLocations} />
            <button className='primary-button filter-button' style={{ minWidth: 100 }} onClick={refetchFunction}>Update</button>
          </div>
          <div className="filters">
            <QueryCompanyFilters
              // setCheckedType={setCheckedType}
              setCheckedRealTime={setCheckedRealTime}
              setCheckedSelfService={setCheckedSelfService}
              refetchFunction={refetchFunction}
            />
          </div>
        </div>
      </Container>
      {/* <Banner></Banner> */}
    </Layout>
  );
  if (error) return `Error! ${error.message}`;

  return (
    <Layout>
      <HeroSection title='Autoverhuurders'></HeroSection>
      <Container>
        <div className='breadcrumb'>
          <Link to='/'>Home</Link> {`>`} Autoverhuurders
        </div>
        <div className="filter-row">
          {/* TODO: ADD COMPANIES FILTER TO SCHEMA => CREATE NEW QUERYLOCATIONS COMPONENT FOR LOCATIONS  */}
          <div className="location-search">
            {console.log('checkedLocations in autoverhuurders.tsx', checkedLocations)}
            <QueryCompanyLocations setCheckedLocations={setCheckedLocations} />
            <button className='primary-button filter-button' style={{ minWidth: 100 }} onClick={refetchFunction}>Update</button>
          </div>
          <div className="filters">
            <QueryCompanyFilters
              // setCheckedType={setCheckedType}
              setCheckedRealTime={setCheckedRealTime}
              setCheckedSelfService={setCheckedSelfService}
              refetchFunction={refetchFunction}
            />
          </div>
        </div>
        {data.companies.data.length === 0 && <p>Geen verhuur bedrijven gevonden. Probeer het met een ander filter.</p>}

        <div className='map-button' onClick={mapHandler}>{!displayMap ? (<img src={MapIcon} alt="Map Icon" width={32} height={32}></img>) : (<img src={CloseIcon} width={32} height={32} alt="Close Icon"></img>)}</div    >
        <div className='cards'>
          {data.companies.data.map((item, index) => (
            <Link to={`/autoverhuurders/${item.attributes.name}`.toLocaleLowerCase().replace(/ +/g, '-')} key={index}>
              <div className="card">
                <div className="card__image bg-white">
                  <img src={item.attributes.logo.data ? item.attributes.logo.data.attributes.url : ''} alt={item.attributes.logo.data ? item.attributes.logo.data.attributes.alternativeText : 'logo'} style={{ padding: 48 }}></img>
                </div>
                <div className="card__content">
                  <div className="description-grid">
                    <div>
                      <span className="description-label">
                        Locatie
                      </span>
                      <p>{item.attributes.city}</p>
                      <span className="description-label">
                        Website
                      </span>
                      <p><a href={`https://${item.attributes.contact.website}`} target="_blank" rel="noreferrer">{item.attributes.contact?.website}</a></p>
                    </div>
                    <div>
                      <span className="description-label">
                        Telephone
                      </span>
                      <p><a href={`tel:${item.attributes.contact?.countryCode}${item.attributes.contact?.phone}`}>{item.attributes.contact?.countryCode} {item.attributes.contact?.phone}</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {data.companies.data.length !== 0 &&
          <div className="load-more">
            <button className="secondary-button" disabled={data.companies.meta.pagination.pageCount === 1} onClick={() => setPageLimit((prev) => prev + FETCHMOREITEMS)}>Meer...</button>
          </div>
        }


        {displayMap &&
          <Map data={data.companies.data} compind={compind} />
        }

      </Container>
      {/* <Banner></Banner> */}
    </Layout>
  )
}

export default Companies;



