import Container from '@mui/material/Container';

/* eslint-disable-next-line */

const faqItems = [
    { question: 'Hoe vind ik de beste prijs?', answer: 'Dit kan op twee manieren: 1) door te zoeken op voertuig; en 2) door te zoeken op autoverhuurders bij jou in de buurt.', strapi_id: 1 },
    { question: 'Kan ik direct reserveren?', answer: 'Nee, dat kan alleen via het real time huurplatform van RentVisie (coming soon). Je kunt via de website wel een offerte opvragen of een reserveringsaanvraag doen.', strapi_id: 2 },
    { question: 'Hoe kan ik mijn verhuurbedrijf aanmelden?', answer: 'Neem contact met ons op via www.rentvisie.nl. Aanmelden is gratis!', strapi_id: 3 },
]

export function Faq() {
    return (
        <Container>
            <div className='faq__wrapper'>
                <div className="headlines">
                    <div className="headline__highlighted">
                        FAQ
                    </div>
                    <div className="headline__title">
                        <h2>
                            Veelgestelde vragen
                        </h2>
                    </div>
                </div>
                {faqItems.map((item) => (
                    <div key={item.strapi_id}>
                        <hr></hr>
                        <p className='question'>
                            {item.question}
                        </p>
                        <p className="answer">
                            {item.answer}
                        </p>
                    </div>
                ))}
            </div>
        </Container>
    );
}

export default Faq;
