import { SliderFilter } from "./filters/slider";
import { RealtimeFilter } from "./filters/realtime";
import { TypeFilter } from "./filters/types";

export function ClassFilters({ setCheckedType, setCheckedRealTime, setPriceValues, refetchFunction, handleClose, data }) {
    return (
        <div style={{paddingTop:'2rem'}}>
            <h3>Filteren:</h3>
            <TypeFilter data={data} setCheckedType={setCheckedType} />
            <RealtimeFilter data={data} setCheckedRealTime={setCheckedRealTime} />
            <SliderFilter data={data} setPriceValues={setPriceValues}></SliderFilter>
            <div style={{marginTop:'2rem'}}>
            <button onClick={() => {refetchFunction(1); handleClose()}} className='primary-button'>Toepassen</button> <button className="secondary-button filter-button" onClick={handleClose}> Sluiten </button>
            </div>
        </div>
    )
}