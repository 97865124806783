import { Container } from '@mui/system';
import Open from '../../images/icons/green-circle.png';
import Closed from '../../images/icons/red-circle.png';

export const CompanyInfo = ({ contact, opening, shortDescription, longDescription, logo }) => (
    <Container>
        <div className="company-info__wrapper">
            <div className="company-info__flex">
                <div className="company-info__content">
                    {console.log('logo in company info', logo)}
                    {logo &&
                        <img src={logo.url} alt={logo.alternativeText} style={{ width: 200, height: "auto" }}></img>
                    }
                    <p>{shortDescription}</p>
                    <div className="company-contact__grid">
                        <div>
                            <span className="description-label">Adres</span>
                            <p className='description'>{contact.street} {contact.houseNumber} <br></br> {contact.postalCode} {contact.city}</p>
                        </div>
                        <div>
                            <span className="description-label">Telefoon</span>
                            <p className='description'><a href={`tel:${contact.countryCode}${contact.phone}`}>{contact.countryCode}{contact.phone}</a></p>
                        </div>
                        <div>
                            <span className="description-label">Website</span>
                            <p className='description'><a href={`https://${contact.website}`} target='_blank' rel="noreferrer">{contact.website}</a></p>
                        </div>
                    </div>
                    {longDescription.data &&
                        <>
                            <span className="description-label">Extra Informatie</span>
                            <p>{longDescription.data.longDescription}</p>
                        </>
                    }
                    {/* TODO: Convert to markdown */}
                </div>
                <div className="company-info__opening">
                    <div className="openinghours__wrapper">
                        <div className="openinghours__title"><h2>Openingstijden</h2></div>
                        <div className="openinghours">
                            <ul>
                                <li className="li-flex">
                                    <div>{opening.openOnMonday ? <img src={Open} alt='open icon' style={{ width: 12 }}></img> : <img src={Closed} alt='open icon' style={{ width: 12 }}></img>} Maandag</div>
                                    <div>{opening.openOnMonday ? opening.openingHoursMonday : 'Gesloten'}</div>
                                </li>
                                <hr></hr>
                                <li className="li-flex">
                                    <div>{opening.openOnTuesday ? <img src={Open} alt='open icon' style={{ width: 12 }}></img> : <img src={Closed} alt='open icon' style={{ width: 12 }}></img>} Dinsdag</div>
                                    <div>{opening.openOnTuesday ? opening.openingHoursTuesday : 'Gesloten'}</div>
                                </li>
                                <hr></hr>
                                <li className="li-flex">
                                    <div>{opening.openOnWednesday ? <img src={Open} alt='open icon' style={{ width: 12 }}></img> : <img src={Closed} alt='open icon' style={{ width: 12 }}></img>} Woensdag</div>
                                    <div>{opening.openOnWednesday ? opening.openingHoursWednesday : 'Gesloten'}</div>
                                </li>
                                <hr></hr>
                                <li className="li-flex">
                                    <div>{opening.openOnThursday ? <img src={Open} alt='open icon' style={{ width: 12 }}></img> : <img src={Closed} alt='open icon' style={{ width: 12 }}></img>} Donderdag</div>
                                    <div>{opening.openOnThursday ? opening.openingHoursThursday : 'Gesloten'}</div>
                                </li>
                                <hr></hr>
                                <li className="li-flex">
                                    <div>{opening.openOnFriday ? <img src={Open} alt='open icon' style={{ width: 12 }}></img> : <img src={Closed} alt='open icon' style={{ width: 12 }}></img>} Vrijdag</div>
                                    <div>{opening.openOnFriday ? opening.openingHoursFriday : 'Gesloten'}</div>
                                </li>
                                <hr></hr>
                                <li className="li-flex">
                                    <div>{opening.openOnSaturday ? <img src={Open} alt='open icon' style={{ width: 12 }}></img> : <img src={Closed} alt='open icon' style={{ width: 12 }}></img>} Zaterdag</div>
                                    <div>{opening.openOnSaturday ? opening.openingHoursSaturday : 'Gesloten'}</div>
                                </li>
                                <hr></hr>
                                <li className="li-flex">
                                    <div>{opening.openOnSunday ? <img src={Open} alt='open icon' style={{ width: 12 }}></img> : <img src={Closed} alt='open icon' style={{ width: 12 }}></img>} Zondag</div>
                                    <div>{opening.openOnSunday ? opening.openingHoursSunday : 'Gesloten'}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Container>
)