import { RealtimeFilter } from "./filters/realtime";
import { CompanySelfServiceFilter } from "./filters/selfservice";

export function CompanyFilters({ setCheckedRealTime, setCheckedSelfService, refetchFunction, handleClose, data }) {
    return (
        <div style={{paddingTop:'2rem'}}>
            <h3>Filteren:</h3>          
            <RealtimeFilter data={data} setCheckedRealTime={setCheckedRealTime} /> 
            <CompanySelfServiceFilter data={data} setCheckedSelfService={setCheckedSelfService} />
            <div style={{marginTop:'2rem'}}>
                <button onClick={() => {refetchFunction(); handleClose()}} className='primary-button'>Toepassen</button> <button className="secondary-button filter-button" onClick={handleClose}> Sluiten </button>
            </div>
        </div>
    )
}