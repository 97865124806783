import Container from '@mui/material/Container';
import Logo from '../../images/logo.png'
import { Link } from 'gatsby';

export default function Footer() {
    return (
        <Container>
            <div className='footer-wrapper'>
                <div>
                    <img src={Logo} alt="RentVisie Logo" width={200}></img>
                </div>
                <div>
                    <h3>Links</h3>
                    <ul>
                        <li><Link to="/huren">Wagenpark</Link></li>
                        <li><Link to="/autoverhuurders">Autoverhuurders</Link></li>
                    </ul>
                </div>
                <div>
                    <h3>Overig</h3>
                    <ul>
                        <li><a href='https://www.rentvisie.nl'>Powered by RentVisie</a></li>
                    </ul>
                </div>
            </div>
        </Container>
    )
}