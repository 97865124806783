import Container from '@mui/material/Container';
import { Link } from 'gatsby'
import { GetClasses } from "../components/GetClasses";
import { HeroSection } from '../components/hero';
import CityLinks from '../components/landingpage/citylinks';
import SeoText from '../components/landingpage/seotext';
import Layout from '../components/layout';

export function Category({ pageContext }) {
  const { name, information } = pageContext
  console.log(information)
  return (
    <Layout>
      <HeroSection title={`${name} huren`}></HeroSection>
      <Container>
        <div className='breadcrumb' aria-label="breadcrumb">
          <Link to='/'>home</Link> {`>`} <Link to='/huren'>huren</Link> {`>`} {name}
        </div>
        <GetClasses name={name} />
        <CityLinks category={name} />
        <div className='additional-info-wrapper'>
          <SeoText body={information.data.information} ></SeoText>
        </div>
      </Container>
    </Layout>
  );
}

export default Category;
