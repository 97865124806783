const CreateBookingLayer = (data) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
        'event': `purchase`,
        'ecommerce': {
            'items': [{
                'item_name': `${data.displayName}`,
                'item_id': `${data.displayName}`,
                'price': 121,
                'price_excl_vat': 100,
                'currency': 'EUR',
                'quantity': 1
            }],
            "value": 100,
            "tax": 21,
            "currency": "EUR",
            'company': `${data.companyName}`,
            'pickup': `${data.pickupDate}`,
            'return': `${data.returnDate}`
        },
    });
};

export default CreateBookingLayer;