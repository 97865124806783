import { Link } from 'gatsby'
import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Icon } from "leaflet";
import CarIcon from "../../images/icons/car-map-icon.png"

// TODO - IMPLEMENT https://www.npmjs.com/package/react-geocode
function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export const Map = ({ data, compind }) => {
    let geoLocations = {}
    if (compind) {
        geoLocations = data.map(item => ([item.attributes.contact.latitude, item.attributes.contact.longitude]))
    }
    if (!compind) {
        geoLocations = data.map(item => ([item.attributes.company.data.attributes.contact.latitude, item.attributes.company.data.attributes.contact.longitude]))
    }
    //const geoLocations = data.map((item) => ([item.attributes.company.data.attributes.contact.latitude, item.attributes.company.data.attributes.contact.longitude]))
    const position = geoLocations[0]


    if (typeof window !== 'undefined') {
        const customIcon = new Icon({
            iconUrl: CarIcon,
        });

        if (!compind) {
            return (
                <MapContainer center={[52.1917303276392, 5.50825209049204]} zoom={8} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {data.map((item) => (
                        <Marker
                            position={[(item.attributes.company.data.attributes.contact.latitude * getRandomArbitrary(0.99995, 1)), item.attributes.company.data.attributes.contact.longitude * getRandomArbitrary(0.99995, 1)]}
                            icon={(!!customIcon ? customIcon : null)}
                        >
                            <Popup>
                                <img src={item.attributes.images.data[0].attributes.url} alt="Placeholder" className='popup__image'></img>
                                <div className='popup__content'>
                                    <h3>{item.attributes.displayName}</h3>
                                    <div className='product-details__grid'>
                                        <div>
                                            <span className='description-label'>
                                                V.a.
                                            </span>
                                            <p className='description'>
                                                € {item.attributes.fromPrice.toFixed(2).toString().replace(".", ",")}
                                            </p>
                                        </div>
                                        <div>
                                            <span className='description-label'>
                                                Door:
                                            </span><p className='description'>{item.attributes.company.data.attributes.name}</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Link
                                            to={`/huren/${item.attributes.category?.data?.attributes?.name?.toLowerCase().replace(/ +/g, '-')}/${item.attributes.displayName?.toLowerCase().replace(/ +/g, '-')}-${item.attributes.company.data.attributes.name.toLowerCase().replace(/ +/g, '-')}`}
                                            className='primary-button map-popup-button'
                                        >
                                            Meer Info
                                        </Link>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    ))}

                </MapContainer>
            )
        } else {
            return (
                <MapContainer center={[52.1917303276392, 5.50825209049204]} zoom={8}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {data.map((item) => (
                        <Marker
                            position={[(item.attributes.contact.latitude * getRandomArbitrary(0.99995, 1)), item.attributes.contact.longitude * getRandomArbitrary(0.99995, 1)]}
                            icon={(!!customIcon ? customIcon : null)}
                        >
                            <Popup>
                                <img src={item.attributes.logo.data ? item.attributes.logo.data.attributes.url : <p>no logo</p>} alt={item.attributes.logo.data ? item.attributes.logo.data.attributes.alternativeText : 'no logo'} className='popup__image'></img>
                                <div className='popup__content'>
                                    <h3>{item.attributes.name}</h3>
                                    <div className='product-details__grid'>
                                        <div>
                                            <span className='description-label'>
                                                Locatie:
                                            </span>
                                            <p className='description'>
                                                {item.attributes.contact.street + ' ' + item.attributes.contact.houseNumber}  <br></br>
                                                {item.attributes.contact.city}
                                            </p>
                                        </div>
                                        <div>
                                            <span className='description-label'>
                                                Telefoon:
                                            </span>
                                            <p className='description'> {item.attributes.contact.countryCode}{item.attributes.contact.phone}</p>
                                        </div>
                                        <div>
                                            <span className='description-label'>
                                                Website:
                                            </span>
                                            <p className='description'> {item.attributes.contact.website}</p>
                                        </div>
                                        <div>
                                            <span className='description-label'>
                                                Self Service:
                                            </span><p className='description'>{item.attributes.selfService}</p>
                                        </div>
                                        <div>
                                            <span className='description-label'>
                                                Real Time:
                                            </span><p className='description'>{item.attributes.realTime}</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Link
                                            to={`/autoverhuurders/${item.attributes.name.toLowerCase().replace(/ +/g, '-')}`}
                                            className='primary-button map-popup-button'
                                        >
                                            Meer Info
                                        </Link>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    ))}

                </MapContainer>

            )
        }
    };
    return null;
}