import { useStaticQuery, graphql, Link } from "gatsby"
import Container from '@mui/material/Container';

export function GetCategories() {
  const data = useStaticQuery(graphql`
    {
      allStrapiCategory {
        nodes {
          name
          image {
            caption
            alternativeText
            url
          }
          strapi_id
        }
      }
    }
  `)
  const categories = data.allStrapiCategory.nodes
  categories.sort((c1, c2) => (c1.name > c2.name) ? 1 : -1)
  return (
    <Container>
      <h2>Zoek door alle voertuigen en vraag een vrijblijvende offerte</h2>
      <div className='cards'>
        {categories.map((category) => (
          <Link to={`/huren/${category.name.toLowerCase().replace(/ +/g, '-')}`} key={category.strapi_id}>
            <div className='card category__card'>
              <img
                src={category.image.url}
                width={120}
                style={{ marginBottom: 12 }}
                alt={category.image.alternativeText}
              ></img>
              <h3>{category.name.toUpperCase()}</h3>
            </div>
          </Link>
        ))}
      </div>
    </Container>
  )
} 