import fetch from 'isomorphic-fetch';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import "@fontsource/nunito" // Defaults to weight 400 with all styles included.


const httpLink = new HttpLink({
    uri: `${process.env.GATSBY_STRAPI_API_URL}/graphql/`,
    headers: {
        Authorization: `Bearer ${process.env.GATSBY_STRAPI_TOKEN}`
    },
    fetch,
})

const apolloClient = new ApolloClient({
    link: httpLink, //THIS IS THE URL OF THR GRAPHQL WE WANTO TO QUERY
    cache: new InMemoryCache(),
});

const wrapRootElement = ({ element }) => (
    <ApolloProvider client={apolloClient}>
        <GoogleReCaptchaProvider reCaptchaKey='6LeaYE8dAAAAANmMDTrJoq4emIGKjjwiTKRsqcbC'>
            {element}
        </GoogleReCaptchaProvider>
    </ApolloProvider>
)

export { wrapRootElement }