import * as React from 'react';
import { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useQuery } from "@apollo/client";
import { GET_CLASS_FILTERS, GET_COMPANY_FILTERS } from "../../graphql/Schema";

function LocationFilter({ data, setCheckedLocations, city }) {
    const [checked, setChecked] = useState([city])
    let locations = {}
    if (data.companies !== undefined) {
        locations = data.companies.data.map(item => item.attributes.city)
    }
    if (data.classes !== undefined) {
        locations = data.classes.data.map(item => item.attributes.company.data.attributes.city)
    }
    const locationsSet = new Set(locations)
    const locationsArray = Array.from(locationsSet)

    useEffect(() => {
        setCheckedLocations(checked)
    }, [checked])

    return (
        <Autocomplete
            multiple
            id="checkboxes-locations"
            options={locationsArray}
            disableCloseOnSelect
            value={checked}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option}
                </li>
            )}
            sx={{
                // width: 300,
                background: 'white',
                height: 55,
                // '& .MuiFormControl-root': {
                //     height: '100%',
                // },
                "& label.Mui-focused": {
                    color: "#01E8B8",
                  },
                '& .MuiOutlinedInput-root': {
                    background: 'white',
                    overflow: 'auto',
                    flexWrap: 'nowrap',
                    "&.Mui-focused fieldset": {
                        borderColor: "#01E8B8"
                      }
                },
            }}
            onChange={(event, value) => {setChecked(value)}}
            renderInput={(params) => (
                <TextField {...params} label="Locaties" placeholder="Selecteer..." />
            )}
        />
    );
}

export function QueryClassLocations({setCheckedLocations, setFilters, city, name}) {
    const { loading, error, data } = useQuery(GET_CLASS_FILTERS, {
        variables: {
            classesInput: { category: { name: { eqi: name.toLowerCase().replace(/ +/g, '-') } } }
        }
    })
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    return <LocationFilter data={data} setCheckedLocations={setCheckedLocations} city={city}></LocationFilter>
}

export function QueryCompanyLocations({setCheckedLocations}) {
    const { loading, error, data } = useQuery(GET_COMPANY_FILTERS)
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    return <LocationFilter data={data} setCheckedLocations={setCheckedLocations}></LocationFilter>
}
