import { Container } from '@mui/system';
import Open from '../../images/icons/green-circle.png';
import Closed from '../../images/icons/red-circle.png';
import InfoIcon from '../../images/icons/info-icon.png';


console.log ('ProductHighlights')
export const ProductHighlights = ({ contact, companyName, fromPrice, setOpen, selfService, realTime, setOpenPrices }) => (

        <div className="product-details">
            <div className="product-details__grid">         
               <div>
                <span className="description-label">
                  Bedrijf
                </span>
                <p className='description'>{companyName}</p>
              </div>  
              <div>
                <span className="description-label">
                  Website
                </span>
                <p><a href={`https://${contact.website}`} target="_blank" rel="noreferrer" className='description'>{contact.website}</a></p>
              </div>
              <div>
                <span className="description-label">
                  Real Time? 
                </span>
                <p className='description'>{selfService === 'Yes' ? 'Ja' : 'Nee' }</p>
              </div>            
              <div>
                <span className="description-label">
                  Self Service?
                </span>
                <p className='description'>{realTime === 'Yes' ? 'Ja' : 'Nee'}</p>
              </div>           
            </div>
            <div className='row-50' style={{ marginTop: 12 }}>
              <div className="product-details__price">
                V.a. € {fromPrice.toFixed(2).toString().replace(".", ",")} <img src={InfoIcon} alt='info-icon' className='icon-button' onClick={() => setOpenPrices(true)}></img>
              </div>
              <div className="product-details__book">
                <button className="primary-button" onClick={setOpen}>Reserveren / Offerte</button>
              </div>
            </div>
          </div>
    
)