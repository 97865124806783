import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_CLASSES, GET_CLASS_FILTERS } from "../graphql/Schema";
import { useEffect, useState } from "react";
import { Link } from "gatsby";
import { QueryClassLocations } from "./filters/locations";
import { QueryClassFilters } from "./filters/QueryFilters";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Map } from "./map/map";
import MapIcon from "../images/icons/map-icon.png";
import CloseIcon from "../images/icons/close-icon.png";
import Pagination from '@mui/material/Pagination';

//Later Phase//
// Company Filter
// Radius Search

export function GetClasses({ name, city }) {
    const [priceValues, setPriceValues] = useState<number[]>([0, 500]);
    const [checkedRealTime, setCheckedRealTime] = useState([]);
    const [checkedType, setCheckedType] = useState([]);
    const [checkedLocations, setCheckedLocations] = useState([]);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState<string[]>(["fromPrice:asc"])
    const [displayMap, setDisplayMap] = useState(false)
    const compind = false;
    const maxLength = 50;

    const handleChangePagination = (element, page) => {
        setPage(page)
    }

    const FETCHMOREITEMS = 3;

    // Filter Variables
    const input = {
        fromPrice: { between: priceValues }
    }
    if (city) {
        input.company = { city: { eq: city } }
    }
    if (name) {
        input.category = { name: { eq: name.toLowerCase().replace(/ +/g, '-')}}
    }

    

    const refetchFunction = (parsedPage) => {
        // add Properties to input object based on Checked Filters
        if (!parsedPage) {
            const parsedPage = 1;
        } 

        if (checkedLocations && checkedLocations.length > 0 && checkedLocations.toString()) {
            input.company = { city: { in: checkedLocations } }
        }
        if (checkedType.length > 0) {
            input.type = { in: checkedType }
        }
        if (checkedRealTime.length > 0) {
            input.company = { realTime: { in: checkedRealTime } }
        }
        if (name) {
            input.category = { name: { eqi: name.toLowerCase().replace(/ +/g, '-')}}
        }

        const refetchObject = {
            classesInput: input,
            sort: sort,
            pagination: { pageSize: 6, page: parsedPage }
        }
        refetch(refetchObject)
    }

    const handleChangeSort = (event: SelectChangeEvent) => {
        setSort([event.target.value] as string[])
    }

    useEffect(() => {
        refetchFunction(1)
    }, [sort])

    useEffect(() => {
        refetchFunction(page)
    }, [page])

    const mapHandler = () => {
        if (displayMap) {
            setDisplayMap(false)
        } else {
            setDisplayMap(true)
        }
    }

    const { loading, error, data, refetch } = useQuery(GET_CLASSES, {
        variables: {
            // classesInput: { category: { name: { eq: name.toLowerCase().replace(/ +/g, '-') }} },
            classesInput: {},
            pagination: { pageSize: 6}
            // sort: sort,
        },
    }
    );

    if (loading) {
       return <p>Loading...</p>
    }
    if (error) return `Error! ${error.message}`;


    return (
        <div className="classes-wrapper">
            <div className="filter-row">
                <div className="location-search">
                    <QueryClassLocations setCheckedLocations={setCheckedLocations} city={city} name={name} />
                    <button className='primary-button filter-button' style={{ minWidth: 100 }} onClick={() => {refetchFunction(1)}}>Update</button>
                </div>
                <div className="filters">
                    <FormControl>
                        <InputLabel id="sort-select-label">Sorteer</InputLabel>
                        <Select
                            labelId="sort-select-label"
                            id="sort-select"
                            value={sort}
                            label="Sorteer"
                            onChange={handleChangeSort}
                            sx={{ marginRight: '1rem' }}
                        >
                            <MenuItem value="fromPrice:desc">Prijs (aflopend)</MenuItem>
                            <MenuItem value="fromPrice:asc">Prijs (oplopend)</MenuItem>
                        </Select>
                    </FormControl>

                    <QueryClassFilters
                        setCheckedType={setCheckedType}
                        setPriceValues={setPriceValues}
                        setCheckedRealTime={setCheckedRealTime}
                        refetchFunction={refetchFunction}
                        name={name}
                    />
                </div>
            </div>
            <div className="classes__items">
                <div className='map-button' onClick={mapHandler}>
                    {!displayMap
                        ? <img src={MapIcon} alt="Map Icon" width={32} height={32}></img>
                        : <img src={CloseIcon} width={32} height={32} alt="Close Icon"></img>
                    }
                </div>
                {data.classes.data.length === 0 &&
                    <div style={{ border: '1px solid gray', padding: 24, marginTop: 24, textAlign: 'center' }}>
                        <p>Helaas geen resultaten.</p>
                        <p>
                            Update jouw filters of vind de dichtsbijzijnde autoverhuurder:
                            <div>
                                <Link className='' to='/autoverhuurders' style={{ fontWeight: 600 }}>Bekijk alle Autoverhuurders</Link>
                            </div>
                        </p>

                    </div>
                }
                <div className='cards'>
                    {data.classes.data.map((item, index) => (
                        <Link to={`/huren/${item.attributes.category.data.attributes.name.toLowerCase().replace(/ +/g, '-')}/${item.attributes.displayName.replace(/ +/g, '-').toLowerCase()}-${item.attributes.company.data.attributes.name.replace(/ +/g, '-').toLowerCase()}`} key={index}>
                            <div className="card">
                                <div className="card__image">
                                    <img src={item.attributes.images.data[0].attributes.url} alt="test"></img>
                                </div>
                                <div className="card__content">
                                    <span className="title">{item.attributes.displayName}</span> <br></br>
                                    V.a.  € {item.attributes.fromPrice.toFixed(2).toString().replace(".", ",")}
                                    <div className="description-grid">
                                        <div>
                                            <span className="description-label">
                                                Inbegrepen KM
                                            </span>
                                            <p>{item.attributes.includedMileageDay} km</p>
                                            <span className="description-label">
                                                Self-service?
                                            </span>
                                            <p>{item.attributes.company.data.attributes.selfService === 'No' ? 'Nee' : 'Ja'}</p>
                                            <span className="description-label">
                                                Website
                                            </span>
                                            <p><a href={`https://${item.attributes.company.data.attributes.contact.website}`}>{item.attributes.company.data.attributes.contact.website}</a></p>
                                        </div>
                                        <div>
                                            <span className="description-label">
                                                Real Time?
                                            </span>
                                            <p>
                                                {item.attributes.company.data.attributes.realTime === 'No' ? 'Nee' : 'Ja'}
                                            </p>
                                            <span className="description-label">
                                                Plaats
                                            </span>
                                            <p>{item.attributes.company.data.attributes.city.substring(0, maxLength)}</p>
                                            <span className="description-label">
                                                Bedrijf
                                            </span>
                                            <p>{item.attributes.company.data.attributes.name.substring(0, maxLength)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            {data.classes.data.length > 0 &&
                // <div className="load-more">
                //     <button
                //         className="secondary-button"
                //         disabled={data.classes.meta.pagination.pageCount === 1}
                //         onClick={() => setPage((prev) => prev + 1)}
                //     >
                //         Meer...
                //     </button>
                // </div>
                <Pagination count={data.classes.meta.pagination.pageCount} page={page} onChange={handleChangePagination} variant="outlined" shape="rounded" />
            }

            {displayMap &&
                <Map data={data.classes.data} compind={compind} />
            }
        </div >
    )
}
