import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PriceTable from './PriceTable';
import Calendar from './Calendar';

export const PriceDialog = ({ fromPrice, rates, open, handleClose }) => {

    const [hours, setHours] = useState();
    const [days, setDays] = useState();
    const [multiDays, setMultiDays] = useState(0);
    const [weekends, setWeekends] = useState();
    const [weeks, setWeeks] = useState();
    const [months, setMonths] = useState();
    const [years, setYears] = useState();

    // price = weekprijs per dag * aantal dagen. 
    //Op dit moment is de weekprijs nog een volledgie week prijs (dwz 7 daags prijs). 
    //Daarom wordt de week prijs gecorrigeerd door te delen door aantal dagen in een week (7), idem voor jaar, weekend, maand.
    let correctieFactor = 1; // assume no correctie is nodig

    let price = 0;
    if (hours === 0) {
        //no action needed: no price for 0 minutes
        return
    } else {
        if (hours <= 4) {
            if (rates.some(e => e.period === 'hour')) {
                const hour = rates.filter((e) => e.period === 'hour')
                price = hours * hour[0].price;
            } else if (rates.some(e => e.period === 'halfday')) {
                const halfday = rates.filter((e) => e.period === 'halfday')
                price = halfday[0].price;
            } else if (rates.some(e => e.period === 'day')) {
                const day = rates.filter((e) => e.period === 'day')
                price = days * day[0].price;
            } else {
                price = rates[0].price
            }
        } else if (weekends > 0) {
            if (rates.some(e => e.period === 'weekend')) {
                const weekend = rates.filter((e) => e.period === 'weekend')
                price = days / correctieFactor * weekend[0].price;
            } else if (rates.some(e => e.period === 'day')) {
                const day = rates.filter((e) => e.period === 'day')
                price = days * day[0].price;
            } else {
                price = 0
            }
        } else if (days <= 2 && days > 0) {
            if (rates.some(e => e.period === 'day')) {
                const day = rates.filter((e) => e.period === 'day')
                price = days * day[0].price;
            } else {
                price = 0
            }
        } else if (multiDays > 0) {
            console.log('MultiDays')
            if (rates.some(e => e.period === 'threetosixdays')) {
                const threetosixdays = rates.filter((e) => e.period === 'threetosixdays')
                price = threetosixdays[0].price * days;
            } else if (rates.some(e => e.period === 'day')) {
                const day = rates.filter((e) => e.period === 'day')
                price = days * day[0].price;
            } else {
                price = 0
            }
        } else if (weeks > 0) {
            if (rates.some(e => e.period === 'week')) {
                const week = rates.filter((e) => e.period === 'week')
                price = days / correctieFactor * week[0].price;
            } else if (rates.some(e => e.period === 'day')) {
                const day = rates.filter((e) => e.period === 'day')
                price = days * day[0].price;
            } else {
                price = 0
            }
        } else if (months > 0) {
            if (rates.some(e => e.period === 'month')) {
                const month = rates.filter((e) => e.period === 'month')
                price = days / correctieFactor * month[0].price;
            } if (rates.some(e => e.period === 'week')) {
                const week = rates.filter((e) => e.period === 'week')
                price = days / correctieFactor * week[0].price;
            } else if (rates.some(e => e.period === 'day')) {
                const day = rates.filter((e) => e.period === 'day')
                price = days * day[0].price;
            } else {
                price = 0
            }
        } else if (years > 0) {
            if (rates.some(e => e.period === 'year')) {
                const year = rates.filter((e) => e.period === 'year')
                price = days / correctieFactor * year[0].price;
            } if (rates.some(e => e.period === 'month')) {
                const month = rates.filter((e) => e.period === 'month')
                price = days / correctieFactor * month[0].price;
            } if (rates.some(e => e.period === 'week')) {
                const week = rates.filter((e) => e.period === 'week')
                price = days / correctieFactor * week[0].price;
            } else if (rates.some(e => e.period === 'day')) {
                const day = rates.filter((e) => e.period === 'day')
                price = days * day[0].price;
            } else {
                price = 0
            }
        }
    }
    return (
        <Dialog open={open} onClose={handleClose}>
            
            {<DialogContent>
                {/* Bug in deploy needs to be recreated
                <h2>Bereken Prijs</h2>
                <Calendar
                    setHours={setHours}
                    setDays={setDays}
                    setMultiDays={setMultiDays}
                    setWeeks={setWeeks}
                    setWeekends={setWeekends}
                    setMonths={setMonths}
                    setYears={setYears}
                />
                <div>
                    <br></br>
                </div>
                <h3>Geschatte prijs: € {price >= 0 ? (price !== 0 ? price.toFixed(2).toString().replace(".", ",") : 'Pas de data aan voor een prijs') : fromPrice.toFixed(2).toString().replace(".", ",")  }</h3>
                */}
                <PriceTable rates={rates}></PriceTable>
            </DialogContent>}
            <DialogActions>
                <Button onClick={handleClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
