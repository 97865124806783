import { useState, useEffect } from "react";

export const TypeFilter = ({ data, setCheckedType }) => {
    const [checked, setChecked] = useState([])
    const types = data.classes.data.map(item => item.attributes.type)
    const typesSet = new Set(types)
    const typesArray = Array.from(typesSet)

    const handleCheck = (event) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    useEffect(() => {
        setCheckedType(checked)
    }, [checked])

    return (
        <>
            <p className="description-label">Type:</p>
            {typesArray.map((item, index) => (
                <div key={index} className='filter-checkbox-row'>
                    <label>
                        <input
                            type="checkbox"
                            name="type"
                            value={item}
                            onChange={handleCheck}
                        /> {item === 'sixpersons' ? '6 personen' : item === 'ninepersons' ? '9 personen' : item}
                    </label>
                </div>
            ))}
        </>
    )
}