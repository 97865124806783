import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout';
import '../../styles/reservation-portal/checkout.scss';
import { Button } from '@mui/material';
import differenceInDays from 'date-fns/differenceInDays';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { PageProps } from 'gatsby';
import axios from 'axios'
import { calculateBasePrice, calculateIncludedMileage } from '../../components/search/calculateFunctions';
import { DepositExtra } from '../../interfaces/Extras';
import CheckoutForm from '../../components/search/step-2/checkoutForm';
import MapStep2 from '../../components/search/step-2/checkoutMap';



interface Extra {
    name: string,
    price: number | null,
    id: number
}

const ReservationCheckout = (props: PageProps) => {
    const query = new URLSearchParams(props.location.search);
    const id = query.get("id");
    const [loading, setLoading] = useState(false);
    const pickupDate = query.get("pickupDate");
    const returnDate = query.get("returnDate");
    const [days, setDays] = useState<number>(1)
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState(undefined);
    const [depositExtra, setDepositExtra] = React.useState<DepositExtra>(undefined)
    const [selectedExtras, setSelectedExtras] = React.useState<Extra[]>([])

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setDays(differenceInDays(new Date(returnDate), new Date(pickupDate)) + 1)
    }, [pickupDate, returnDate])

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            url: `${process.env.GATSBY_STRAPI_API_URL}/api/class-search/?id=${id}&pickupDate=${pickupDate}&returnDate=${returnDate}`
        })
            .then((res) => {
                setResult(res)
            })
            .catch((e) => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const handleHoldingAndDeposit = (e: string) => {
        const id = parseInt(e)
        const filterInput = result.data.holdingAndDeposit.filter(extra => extra.id === id)
        setDepositExtra(filterInput[0])
    }

    return (
        <Layout>
            <Container>
                <h1>Checkout</h1>
                {result &&
                    <div className='checkout-wrapper'>
                        <div className='checkout-form'>
                            
                            <h2>Personaliseer uw {result.data.displayName} </h2>
                            <div className='checkout-content__extras'>
                                <h3>Extra's toevoegen</h3>
                                <div className='extra-wrapper'>
                                    <h4>Eigen Risico</h4>
                                    <div className='extra-grid'>
                                        {result.data.holdingAndDeposit.length !== 0
                                            ? result.data.holdingAndDeposit.map((extra) => (
                                                <div key={extra.id}>
                                                    <input
                                                        type="radio"
                                                        id={extra.id}
                                                        name="holdingAndDeposit"
                                                        value={extra.id}
                                                        onChange={(e) => handleHoldingAndDeposit(e.target.value)}
                                                    />
                                                    <label htmlFor={extra.id}>
                                                        <div className="extrabox">
                                                            <h3>{extra.type === 'highRisk' && 'Hoog Eigen Risico'}
                                                                {extra.type === 'mediumRisk' && 'Gemiddeld Eigen Risico'}
                                                                {extra.type === 'lowRisk' && 'Laag Eigen Risico'}
                                                            </h3>
                                                            <div style={{ display: 'flex', gap: 12 }}>
                                                                <p>Borg:</p>
                                                                <p>{extra.deposit}</p>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: 12 }}>
                                                                <p>Eigen Risico:</p>
                                                                <p>{extra.deductible}</p>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: 12 }}>
                                                                <p>Totale Kosten:</p>
                                                                <p>{extra.calcPrice.toFixed(2)}</p>
                                                            </div>
                                                            {/* <p>Totaal: € {calculateExtraPrice(days, cdw.pricesExtra)}</p> */}
                                                        </div>
                                                    </label>
                                                </div>
                                            ))
                                            : <p>U kunt geen eigen risico verlagen voor dit product.</p>
                                        }
                                    </div>
                                </div>
                                <p>Indien gewenst kunt u overige extra's toevoegen bij het ophalen van het voertuig.</p>
                                {/* <div className='extra-wrapper'>
                                Eigen Risico
                                </div>
                                <div className='extra-wrapper'>
                                Kilometers
                            </div>
                            <div className='extra-wrapper'>
                            Accessoires
                            </div> */}
                                {/* <div>
                                <Button variant='contained' fullWidth onClick={handleClickOpen}>Reserveren</Button>
                            </div> */}
                            </div>
                        </div>
                        <div>
                            <div className='checkout-content'>
                                <div className='checkout-content-grid'>
                                    <div><img src={result.data.images[0].url} alt='alt' /></div>
                                    <div style={{ fontSize: 12 }}>
                                        <h1>{result.data.displayName}</h1>
                                        <LocationOnIcon /> {result.data.company.city.toUpperCase()}  <br></br>
                                        <CalendarMonthIcon /> {new Date(pickupDate).toLocaleDateString('nl-NL')} tot {new Date(returnDate).toLocaleDateString('nl-NL')}
                                    </div>
                                </div>
                                <div className='checkout-price-overview'>
                                    <div className='checkout-price-overview__days'>
                                        {days > 1
                                            ? `Huurperiode: ${days} dagen`
                                            : 'Huurperiode: 1 dag'
                                        }
                                    </div>
                                    {result.data.mileage &&
                                        <div style={{marginTop:12}}>
                                            Inbegrepen KM: {(calculateIncludedMileage(days, result.data.mileage, new Date(pickupDate)) >= 9999 ? 'Onbeperkt' : calculateIncludedMileage(days, result.data.mileage, new Date(pickupDate)))}
                                            {result.data.mileage?.priceAdditionalKm && (calculateIncludedMileage(days, result.data.mileage, new Date(pickupDate)) < 9999) &&
                                                 <span><br />daarna: € {result.data.mileage.priceAdditionalKm}/ km</span>
                                            }
                                        </div>
                                    }
                                    <div className='checkout-price-overview__title'>
                                        Pakket
                                    </div>
                                    <div className='checkout-price-overview__item'>
                                        <div>{result.data.displayName}</div>
                                        <div>€ {(calculateBasePrice(days, result.data.prices, new Date(pickupDate)) / 1.21).toFixed(2)}</div>
                                    </div>
                                    <div className='checkout-price-overview__title'>
                                        Extra's
                                    </div>
                                    <div className='checkout-price-overview__item'>
                                        {depositExtra &&
                                            <>
                                                <div>
                                                    {depositExtra.type === 'highRisk' && 'Hoog Eigen Risico'}
                                                    {depositExtra.type === 'mediumRisk' && 'Gemiddeld Eigen Risico'}
                                                    {depositExtra.type === 'lowRisk' && 'Laag Eigen Risico'}
                                                </div>
                                                <div>€ {(depositExtra.calcPrice / 1.21).toFixed(2)}</div>
                                            </>
                                        }
                                    </div>

                                    <div className='checkout-price-overview__title'>
                                        Totaal
                                    </div>
                                    {/* TODO: Calculate Total Price */}
                                    <div className='checkout-price-overview__item'>
                                        <div>Totaal (excl. BTW)</div>
                                        <div>€ {((calculateBasePrice(days, result.data.prices, new Date(pickupDate)) + (depositExtra?.calcPrice || 0)) / 1.21).toFixed(2)}</div>
                                    </div>
                                    <div className='checkout-price-overview__item'>
                                        <div>BTW</div>
                                        <div>€ {(((calculateBasePrice(days, result.data.prices, new Date(pickupDate)) + (depositExtra?.calcPrice || 0)) / 1.21) * 0.21).toFixed(2)}</div>
                                    </div>

                                    <div className='checkout-price-overview__item' style={{ fontWeight: 600 }}>
                                        <div>Totaal (incl. BTW)</div>
                                        <div>€ {(calculateBasePrice(days, result.data.prices, new Date(pickupDate)) + (depositExtra?.calcPrice || 0)).toFixed(2)}</div>
                                    </div>


                                </div>
                                <Button variant='contained' fullWidth onClick={handleClickOpen}>Reserveren</Button>
                                <div style={{ marginTop: 24 }}>
                                    <MapStep2 lat={result.data.company.contact.latitude} lng={result.data.company.contact.longitude} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {open &&
                    <CheckoutForm
                        open={open}
                        setOpen={setOpen}
                        product={result.data.displayName}
                        location={result.data.company.city}
                        depositExtra={depositExtra || {}}
                        pickupDate={pickupDate}
                        returnDate={returnDate}
                        company={result.data.company}
                        totalPrice={(calculateBasePrice(days, result.data.prices, new Date(pickupDate)) + (depositExtra?.calcPrice || 0))}
                    />
                }
            </Container>
        </Layout>
    )
}

export default ReservationCheckout;