import { Link } from "gatsby"
import Container from '@mui/material/Container';
import { HeroSection } from "../components/hero";
import { GetCategories } from "../components/GetCategories";
import Layout from "../components/layout";

export function Categories() {

  return (
    <Layout>
      <HeroSection title='Categoriën'></HeroSection>
      <Container>
        <div className='breadcrumb'>
          <Link to='/'>Home</Link> {`>`} Huren
        </div>
      </Container>
      <GetCategories />
    </Layout>
  );
}

export default Categories;
