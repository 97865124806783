import { useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import { GET_CLASS_FILTERS, GET_COMPANY_FILTERS } from "../../graphql/Schema"
import { FilterButton } from "./FilterButton";
import { ClassFilters } from "../GetClassFilters";
import { CompanyFilters } from "../GetCompanyFilters";
import { useOnClickOutside } from "../../hooks/UseOnClickOutside";

export const QueryClassFilters = ({ setCheckedType, setPriceValues, setCheckedRealTime, refetchFunction, name }) => {
    const ref = useRef();
    const [open, setOpen] = useState(false);
    useOnClickOutside(ref, () => setOpen(false));
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { loading, error, data }  = useQuery(GET_CLASS_FILTERS, {
        variables: {
            classesInput: { category: {name: { eqi: name.toLowerCase().replace(/ +/g, '-') } }}
        }
    })
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <>
            <FilterButton handleClickOpen={handleClickOpen}></FilterButton>
            {data &&
                <div
                    className={`dialog-wrapper ${open ? 'show' : ''}`}
                >
                    <div className="dialog" ref={ref}>
                        <ClassFilters
                            setCheckedType={setCheckedType}
                            setPriceValues={setPriceValues}
                            setCheckedRealTime={setCheckedRealTime}
                            refetchFunction={refetchFunction}
                            handleClose={handleClose}
                            data={data}
                        />
                    </div>
                </div>
            }
        </>
    )
}


export const QueryCompanyFilters = ({ setCheckedType, setCheckedRealTime, setCheckedLocations, setCheckedSelfService,  refetchFunction }) => {
    const ref = useRef();
    const [open, setOpen] = useState(false);
    useOnClickOutside(ref, () => setOpen(false));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { loading, error, data } = useQuery(GET_COMPANY_FILTERS)
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <>
            <FilterButton handleClickOpen={handleClickOpen}></FilterButton>
            {data &&
                <div
                    className={`dialog-wrapper ${open ? 'show' : ''}`}
                >
                    <div className="dialog"  ref={ref}> 
                        <CompanyFilters
                            setCheckedRealTime={setCheckedRealTime}
                            setCheckedSelfService={setCheckedSelfService}
                            refetchFunction={refetchFunction}
                            handleClose={handleClose}
                            data={data}
                        />
                    </div>
                </div>
            }
        </>
    )
}